import { render, staticRenderFns } from "./my-profile-password.vue?vue&type=template&id=dc247098&scoped=true&"
import script from "./my-profile-password.vue?vue&type=script&lang=js&"
export * from "./my-profile-password.vue?vue&type=script&lang=js&"
import style0 from "./my-profile-password.vue?vue&type=style&index=0&id=dc247098&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc247098",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("D:\\asyhub-workspace\\asyhub-platform-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dc247098')) {
      api.createRecord('dc247098', component.options)
    } else {
      api.reload('dc247098', component.options)
    }
    module.hot.accept("./my-profile-password.vue?vue&type=template&id=dc247098&scoped=true&", function () {
      api.rerender('dc247098', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/connectid/pages/profile/my-profile-password.vue"
export default component.exports